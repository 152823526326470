<div class="page-content">
  <section class="horizontal-container center caption">
    <article class="vertical-container center gap-36 caption-title">
      <h1 id="phygital" class="background-box-always">PHYGITAL:</h1>
      <h3 style="font-weight: 400">/fi-juh-tl/</h3>
      <div class="h4-body" style="max-width: 469px; text-align: justify">
        — the concept of using technology to bridge the digital world with the physical world with the purpose
        of providing immutable authenticity and provenance to the products you create, buy or sell.
      </div>
      <button class="btn neon-teal hidden">
        GIVE IT A TRY
        <app-svg-icon iconPath="icons/call_made"></app-svg-icon>
      </button>
    </article>
    <div class="caption-image-block">
      <img alt="" id="chip-img" class="caption-image" style="align-items: unset" src="assets/images/chip_photo.png">
    </div>
  </section>

  <section class="vertical-container gap-36 width-100 mobile-gap-28">
    <div class="section-title">AUTHENTICATE WITH ASSURANCE:</div>
    <div class="caption-description">Tikos provides cutting-edge technology that verifies the authenticity of your
      physical assets and seamlessly links them to a secure digital twin on the blockchain. Say goodbye to counterfeits
      and fraud, and hello to effortless provenance tracking and ownership verification.
    </div>

  </section>

  <section class="vertical-container gap-48 mobile-gap-28 width-100">
    <div class="section-title">BY USING THESE 3 TECHNOLOGIES:</div>
    <article class="horizontal-container gap-24 bullet-points">
      <div class="hwsw-section-part background-hover-box-parent">
        <div id="bp-nfc" class="title background-hover-box">
          NFC
        </div>
        <div class="description">
          A short-range wireless technology that allows two devices to exchange data when they are close together.
          Because NFC is incorporated into every modern mobile phone, it’s the perfect technology for communicating with
          our highly secure Tikos chips.
        </div>
      </div>

      <div class="hwsw-section-part background-hover-box-parent">
        <div id="bp-nft" class="title background-hover-box">
          NFT
        </div>
        <div class="description">
          A unique digital asset that represents ownership of a specific item or piece of content, like an image, video,
          audio file, etc… Unlike a dollar bill or a bitcoin, which are fungible and can be exchanged for another
          identical one, an NFT is unique and cannot be replaced with something else giving each NFT a distinct
          identity.
        </div>
      </div>

      <div class="hwsw-section-part background-hover-box-parent">
        <div id="bp-blockchain" class="title background-hover-box">
          Blockchain
        </div>
        <div class="description">
          Imagine a digital ledger that's shared among many computers instead of being kept in one place. This ledger is
          incredibly secure, transparent, and tamper-proof.
        </div>
      </div>
    </article>
  </section>

  <section id="proof-of-section" class="vertical-container gap-48 center mobile-gap-28">
    <div class="section-title">WE CAN PROVIDE PROOF OF:</div>

    <div id="proof-of-pillars">
      <div class="pillar background-hover-box-parent">
        <div id="pop-authenticity" class="title background-hover-box">Authenticity</div>
        <div class="description">With a unique digital Tikos ID paired with a unique chip ID, an immutable blockchain
          history
          is created providing undisputed origin and assures your product is genuine.
        </div>
      </div>

      <div class="pillar background-hover-box-parent">
        <div id="pop-ownership" class="title background-hover-box">Ownership</div>
        <div class="description">The Tikos Verified Digital Ledger provides proof of ownership offering brand protection
          by identifying and eliminating counterfeits thus reinforcing purchases and sales with assurance.
        </div>
      </div>

      <div class="pillar background-hover-box-parent">
        <div id="pop-provenance" class="title background-hover-box">Provenance</div>
        <div class="description">You value your artifacts, now you can prove the source and history of your valued
          object that is ensured through every peer to peer transaction.
        </div>
      </div>
    </div>
  </section>

  <section class="width-100 vertical-container center gallery-section-wide mobile-gap-28">
    <div class="section-title bottom-pdn-24">WHICH IS USEFUL FOR:</div>
    <div class="gallery top-one">
      <div class="gallery-item background-hover-box-parent" (click)="selectedGalleryItem.set(galleryItems[0])">
        <div class="title athlete background-hover-box">{{ galleryItems[0].title }}</div>
        <div class="img-container">
          <img [src]="galleryItems[0].images[0]" alt="Athletes and Influencers"/>
          <div class="img-overlay">Read more</div>
          <div class="vertical-line"></div>
        </div>
      </div>
      <div class="gallery-item background-hover-box-parent" (click)="selectedGalleryItem.set(galleryItems[1])">
        <div class="title artwork background-hover-box">
          <div class="aln-bottom">{{ galleryItems[1].title }}</div>
        </div>
        <div class="img-container">
          <img [src]="galleryItems[1].images[0]" alt="Artwork"/>
          <div class="img-overlay">Read more</div>
          <div class="vertical-line"></div>
        </div>
      </div>
      <div class="gallery-item background-hover-box-parent" (click)="selectedGalleryItem.set(galleryItems[2])">
        <div class="title collectibles background-hover-box">
          <div class="aln-bottom">{{ galleryItems[2].title }}</div>
        </div>
        <div class="img-container background-hover-box-parent">
          <img [src]="galleryItems[2].images[0]" alt="Collectibles"/>
          <div class="img-overlay">Read more</div>
          <div class="vertical-line"></div>
        </div>
      </div>
      <div class="gallery-item background-hover-box-parent" (click)="selectedGalleryItem.set(galleryItems[3])">
        <div class="title artists background-hover-box">
          <div class="aln-bottom">{{ galleryItems[3].title }}</div>
        </div>
        <div class="img-container">
          <img [src]="galleryItems[3].images[0]" alt="Artists"/>
          <div class="img-overlay">Read more</div>
          <div class="vertical-line"></div>
        </div>
      </div>
    </div>
    <div class="center-timeline">
      <span style="width: 11rem; text-align: end">when God was a baby</span>
      <app-svg-icon iconPath="icons/child_care"></app-svg-icon>
      <div class="horizontal-line"></div>
      <span>nowadays</span>
      <div class="horizontal-line"></div>
      <app-svg-icon iconPath="icons/earth"></app-svg-icon>
      <span style="width: 11rem">the Sun consumes the Earth</span>
    </div>
    <div class="gallery bottom-one">
      <div class="gallery-item background-hover-box-parent" (click)="selectedGalleryItem.set(galleryItems[4])">
        <div class="img-container">
          <div class="vertical-line"></div>
          <img [src]="galleryItems[4].images[0]" alt="Memorabilia"/>
          <div class="img-overlay bottom-one">Read more</div>
        </div>
        <div class="title memorabilia background-hover-box">{{ galleryItems[4].title }}</div>
      </div>
      <div class="gallery-item background-hover-box-parent" (click)="selectedGalleryItem.set(galleryItems[5])">
        <div class="img-container" style="padding-top: 16px">
          <div class="vertical-line"></div>
          <img [src]="galleryItems[5].images[0]" alt="Spirits"/>
          <div class="img-overlay bottom-one">Read more</div>
        </div>
        <div class="title spirits background-hover-box">{{ galleryItems[5].title }}</div>
      </div>
      <div class="gallery-item background-hover-box-parent" (click)="selectedGalleryItem.set(galleryItems[6])">
        <div class="img-container">
          <div class="vertical-line"></div>
          <img [src]="galleryItems[6].images[0]" alt="Brand Authenticity"/>
          <div class="img-overlay bottom-one">Read more</div>
        </div>
        <div class="title brand-authenticity background-hover-box">{{ galleryItems[6].title }}</div>
      </div>
    </div>
  </section>

  <section class="gallery-section-narrow width-100 vertical-container">
    <div class="section-title bottom-pdn-24">WHICH IS USEFUL FOR:</div>
    <div class="narrow-gallery-item" (click)="selectedGalleryItem.set(galleryItems[0])">
      <img [src]="galleryItems[0].images[0]">
      <div class="text-block">
        <div class="title">{{ galleryItems[0].title }}</div>
        <div class="description">{{ galleryItems[0].text }}</div>
      </div>
      <app-svg-icon iconPath="icons/chevron-down"></app-svg-icon>
    </div>
    <div class="narrow-gallery-item" (click)="selectedGalleryItem.set(galleryItems[1])">
      <div class="text-block">
        <div class="title">{{ galleryItems[1].title }}</div>
        <div class="description">{{ galleryItems[1].text }}</div>
      </div>
      <img [src]="galleryItems[1].images[0]"/>
      <app-svg-icon iconPath="icons/chevron-down"></app-svg-icon>
    </div>
    <div class="narrow-gallery-item" (click)="selectedGalleryItem.set(galleryItems[2])">
      <img [src]="galleryItems[2].images[0]">
      <div class="text-block">
        <div class="title">{{ galleryItems[2].title }}</div>
        <div class="description">{{ galleryItems[2].text }}</div>
      </div>
      <app-svg-icon iconPath="icons/chevron-down"></app-svg-icon>
    </div>
    <div class="narrow-gallery-item" (click)="selectedGalleryItem.set(galleryItems[3])">
      <div class="text-block">
        <div class="title">{{ galleryItems[3].title }}</div>
        <div class="description">{{ galleryItems[3].text }}</div>
      </div>
      <img [src]="galleryItems[3].images[0]"/>
      <app-svg-icon iconPath="icons/chevron-down"></app-svg-icon>
    </div>
    <div class="narrow-gallery-item" (click)="selectedGalleryItem.set(galleryItems[4])">
      <img [src]="galleryItems[4].images[0]">
      <div class="text-block">
        <div class="title">{{ galleryItems[4].title }}</div>
        <div class="description">{{ galleryItems[4].text }}</div>
      </div>
      <app-svg-icon iconPath="icons/chevron-down"></app-svg-icon>
    </div>
    <div class="narrow-gallery-item" (click)="selectedGalleryItem.set(galleryItems[5])">
      <div class="text-block">
        <div class="title">{{ galleryItems[5].title }}</div>
        <div class="description">{{ galleryItems[5].text }}</div>
      </div>
      <img [src]="galleryItems[5].images[0]"/>
      <app-svg-icon iconPath="icons/chevron-down"></app-svg-icon>
    </div>
    <div class="narrow-gallery-item" (click)="selectedGalleryItem.set(galleryItems[6])">
      <img [src]="galleryItems[6].images[0]">
      <div class="text-block">
        <div class="title">{{ galleryItems[6].title }}</div>
        <div class="description">{{ galleryItems[6].text }}</div>
      </div>
      <app-svg-icon iconPath="icons/chevron-down"></app-svg-icon>
    </div>
  </section>

  <section class="bullet-points with-image">
    <img alt="" id="landing-app-examples-img" src="assets/images/landing-app-examples.png">

    <article>
      <h2>HOW IT WORKS:</h2>

      <div class="article-section background-hover-box-parent">
        <div class="title background-hover-box">1. Buy our highly secure, affordable chips
          <a href="https://store.tikos.io/" target="_blank" class="btn neon-teal highlighted-orange" style="cursor: pointer !important;">
            BUY NOW</a>
        </div>
        <div>
          It doesn't matter if you want to authenticate a small number of assets or your entire corporate production
          line, Tikos has you covered.
        </div>
      </div>

      <div class="article-section background-hover-box-parent">
        <div class="title background-hover-box">2. Attach the Tikos chip to your asset</div>
        <div>
          With our incredibly small chips, you can discretely affix our chips to your asset.
          We can also provide your own custom logo and app to communicate with the chips.
        </div>
      </div>

      <div class="article-section background-hover-box-parent">
        <div class="title background-hover-box">3. Mint your NFT and Artifact on the blockchain</div>
        <div>
          Within the app you'll need to attach your one of a kind chip to your one of a kind asset.
          You'll be able to add photos or videos of your asset as well as notes describing your asset.
        </div>
      </div>

      <div class="article-section background-hover-box-parent">
        <div class="title background-hover-box">4. Buy and sell with absolute certainty</div>
        <div>
          That's it! Your creation is now reflected on the blockchain forever along with embedded data describing
          your asset and you can now sell your asset as a one of a kind.
        </div>
      </div>
      <!--
      <button class="btn turquoise">
        BUY AND SELL
        <app-svg-icon iconPath="icons/call_made"></app-svg-icon>
      </button>
      -->
    </article>
  </section>

  <app-subscribe/>
</div>

@if (selectedGalleryItem()) {
  <app-modal (overlayClick)="selectedGalleryItem.set(null)">
    <div class="horizontal-container row-reverse hide-modal-content-on-wide" style="margin-bottom: 16px">
      <app-svg-icon
        iconPath="icons/close"
        class="cursor-pointer"
        (click)="selectedGalleryItem.set(null)">
      </app-svg-icon>
    </div>

    <div class="modal-content">
      <img [src]="selectedGalleryItem()!.images[0]">
      <div class="content-block">
        <div class="header">
          {{ selectedGalleryItem()?.title }}
          <app-svg-icon
            iconPath="icons/close"
            class="cursor-pointer hide-modal-content-on-narrow"
            (click)="selectedGalleryItem.set(null)">
          </app-svg-icon>
        </div>
        <div class="description">
          {{ selectedGalleryItem()?.text }}
        </div>
        <button class="btn turquoise hidden">
          GIVE IT A TRY
          <app-svg-icon iconPath="icons/call_made"></app-svg-icon>
        </button>
      </div>
    </div>
  </app-modal>
}
