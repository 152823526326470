import {AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {finalize, Subject, takeUntil} from "rxjs";
import {TagsService} from "../../services/tags.service";
import {Tag} from "../../interfaces/tag.interfaces";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-tag-verification',
  standalone: true,
  imports: [DatePipe],
  templateUrl: './tag-verification.component.html',
  styleUrl: './tag-verification.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TagVerificationComponent implements OnInit, AfterViewInit, OnDestroy {

  tag?: Tag;
  loaded = false;

  private swiperInited = false;

  private destroy$ = new Subject<void>();

  constructor(private activatedRoute: ActivatedRoute, private tagsService: TagsService) { }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.loaded = true)
      )
      .subscribe(params => {
      const picc = params['d'];
      const enc = params['f'];
      const cmac = params['c'];

      this.tagsService.getTagFromEncrypted$(picc, enc, cmac).subscribe({
        next: tag => {
          this.tag = tag;
          if (!this.swiperInited) {
            // need to give time for swiper-container to render
            setTimeout(() => {
              this.initSwiper();
            });
            this.swiperInited = true;
          }
        }
      });
    });
  }

  ngAfterViewInit() {
    if (!this.swiperInited && this.tag) {
      this.initSwiper();

      this.swiperInited = true;
    }
  }

  private initSwiper() {
    if (this.tag!.images?.length) {
      const swiperEl = window.document.getElementsByTagName('swiper-container')[0]

      const params = {
        injectStyles: [`
      .swiper-pagination-bullet {
        width: 12px;
        height: 8px;
        border-radius: 8px;
        opacity: 1;
        background: white;
        border: 1px solid var(--color-black);
      }

      .swiper-pagination-bullet-active {
        width: 48px;
      }
      `],
        pagination: {
          clickable: true,
          renderBullet: function (index: any, className: string) {
            return '<span class="' + className + '"></span>';
          },
        },
      }

      Object.assign(swiperEl!, params);
      (swiperEl! as any).initialize();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
